import { FETCH_ALL_FAVORITES, FETCH_ALL_BAG_ITEMS, DELETE_FROM_BAG, ADD_ITEM_TO_BAG, DELETE_FROM_FAVORITES, LOAD_BAG, BAG_LOADED, LOADED_FAVORITES, LOAD_FAVORITES, FETCH_LOCALLY } from "../constants/actionTypes";
import * as bagAPIs from "../api/addTo";
import { toastifyMessage } from "../handlers/toastifyMessage";

export const handleAddToBag = async (itemType, str, setChange, change, dispatch) => {
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;

  const DON_TYPE = {
    DONATION: 0,
    ORPHANS: 1,
    PROJECT: 2,
    VOLUNTEER: 3,
    HUMAN: 4,
    RENEWAL: 5,
    LATE: 6,
    GIFT: 7
  }

  const DON_ID = {
    DONATION: 0,
    ORPHANS: 1,
    PROJECT: 8,
    VOLUNTEER: 161,
    HUMAN: 158,
    RENEWAL: 1,
    LATE: 1,
    GIFT: 1
  }

  let currentProfile = JSON.parse(localStorage.getItem('profile'));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  
  if (!userLogin) {
    if (!currentProfile) {
      localStorage.setItem(
        "profile",
        JSON.stringify({
          cart: {
            donations: [],
            sukuks: [],
            projects: [],
            humanCases: [],
            orphans: [],
          },
          liked: { donations: [], sukuks: [], projects: [], orphans: [] },
        })
      );
      currentProfile = JSON.parse(localStorage.getItem("profile"));
    }


    if(itemType === "donations")
    {
      currentProfile.cart[itemType].push({ ...str, baseAmount: str.amount });

      if(currentProfile.cart[itemType].filter((item) => parseInt(str.amount) !== parseInt(item.baseAmount) && str.donationId === item.donationId && str.donationFreq === item.donationFreq).length > 0)
      {
        const filteredArray = currentProfile.cart[[itemType]].filter((item) => str.donationId === item.donationId && str.donationFreq === item.donationFreq)

        const newBaseAmount = filteredArray.reduce(
          (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.baseAmount),
          0
        );
        currentProfile.cart[itemType] = currentProfile.cart[itemType].filter((item) => item.donationId !== str.donationId || str.donationFreq !== item.donationFreq)
        currentProfile.cart[itemType].push({...str, baseAmount: newBaseAmount, amount: newBaseAmount})
      }
    }
    else if( itemType === "sukuks")
    {
      currentProfile.cart[itemType].push({ ...str, baseAmount: str.amount, baseFreq: str.donationFreq,  dateOfAdding: new Date().getTime() });

      if(currentProfile.cart[itemType].filter((item) => parseInt(str.amount) !== parseInt(item.baseAmount) && str.sukId === item.sukId && str.donationFreq === item.baseFreq).length > 0)
      {
        const filteredArray = currentProfile.cart[[itemType]].filter((item) => str.sukId === item.sukId && str.donationFreq === item.baseFreq)
        const newBaseAmount = filteredArray.reduce(
          (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.baseAmount),
          0
        );
        currentProfile.cart[itemType] = currentProfile.cart[itemType].filter((item) => item.sukId !== str.sukId || str.donationFreq !== item.baseFreq)
        currentProfile.cart[itemType].push({...str, baseAmount: newBaseAmount, amount: newBaseAmount , baseFreq: str.donationFreq, dateOfAdding: new Date().getTime()})

      }
    }
    const newProfile = {
      cart: currentProfile.cart,
      liked: currentProfile.liked,
    };
    localStorage.setItem("profile", JSON.stringify(newProfile));
    await dispatch({type: FETCH_LOCALLY})
    setChange(change + 1);
    toastifyMessage("Added!", undefined, "success");
  } else {
    await dispatch(
      addToBag({
        cart: {
          donID: str?.donationId? str?.donationId: str?.sukId,
          userName: userLogin.Results[0]?.name_a,
          userMob: userLogin.Results[0]?.mobile_no,
          userEmail: userLogin.Results[0]?.email,
          itemType: 0,
          amount: str?.amount,
          recuring: str?.donationFreq,
          cart_serial: null,
        },
        language: lng === "arab" ? 1 : 2,
      })
    );
  }

}

export const addToBag = (myobject, onHide, str, history) => async (dispatch) => {
  let cart = {}
  let item = myobject.cart
  if (item.length > 0 && myobject.from === 'camPop') {
    cart = {
      "bag": [...item]
    }
  } else if (item.length > 0) {
    let newBag = []
    for (let i = 0; i < item.length; i++) {
      
      newBag.push({
        "remarks": item[i].remarks ? item[i].remarks : null,
        "dontype_id": item[i].donID,
        "doner_name": item[i].userName,
        "mobile": item[i].userMob,
        "email": item[i].userEmail,
        "orptype_id": item[i].orpTypeID ? item[i].orpTypeID : null,
        "wrkplc_id": item[i].workPlaceID ? item[i].workPlaceID : null,
        "prj_name": item[i].prj_name ? item[i].prj_name : null,
        "projectYear": item[i].projectYear ? item[i].projectYear : null,
        "template_id": item[i].template_id ? item[i].template_id : null,
        "vln_id": item[i].vln_id ? item[i].vln_id : null,
        "vln_service_cod": item[i].vln_service_cod ? item[i].vln_service_cod : null,
        "vln_date_from": item[i].vln_date_from ? item[i].vln_date_from : null,
        "vln_date_to": item[i].vln_date_to ? item[i].vln_date_to : null,
        "vln_desc": item[i].vln_desc ? item[i].vln_desc : null,
        "Mastercard_order_id": item[i].Mastercard_order_id ? item[i].Mastercard_order_id : null,
        "item_id": "1",
        "item_type": item[i].itemType,
        "country_id": item[i].country_id ? item[i].country_id : null,
        "amount": item[i].amount,
        "recuring": item[i].recuring ? item[i].recuring : null,
        "month_count": item[i].month_count ? item[i].month_count : null,
        "humanCase": item[i].humanCase ? item[i].humanCase : null,
        "humanYear": item[i].humanYear ? item[i].humanYear : null,
        "valid_flag": item[i].valid_flag ? item[i].valid_flag : null,
        "sponsor_id": item[i].sponsor_id ? item[i].sponsor_id : null,
        "cart_serial": item[i].cart_serial ? item[i].cart_serial : null,
        "orphan_id": item[i].orphan_id ? item[i].orphan_id : null,
        "transaction_number": item[i].transaction_number ? item[i].transaction_number : null,
        "transaction_year": item[i].transaction_year ? item[i].transaction_year : null,
        "quantity": item[i].quantity? item[i].quantity: 1,
      })
    }
    cart = {
      "bag": [...newBag]
    }

  } else {
    cart = {
      "bag": [{
        "remarks": item.remarks ? item.remarks : null,
        "dontype_id": item.donID,
        "doner_name": item.userName,
        "mobile": item.userMob,
        "email": item.userEmail,
        "orptype_id": item.orpTypeID ? item.orpTypeID : null,
        "wrkplc_id": item.workPlaceID ? item.workPlaceID : null,
        "prj_name": item.prj_name ? item.prj_name : null,
        "projectYear": item.projectYear ? item.projectYear : null,
        "template_id": item.template_id ? item.template_id : null,
        "vln_id": item.vln_id ? item.vln_id : null,
        "vln_service_cod": item.vln_service_cod ? item.vln_service_cod : null,
        "vln_date_from": item.vln_date_from ? item.vln_date_from : null,
        "vln_date_to": item.vln_date_to ? item.vln_date_to : null,
        "vln_desc": item.vln_desc ? item.vln_desc : null,
        "Mastercard_order_id": item.Mastercard_order_id ? item.Mastercard_order_id : null,
        "item_id": "1",
        "item_type": item.itemType,
        "country_id": item.country_id ? item.country_id : null,
        "amount": item.amount,
        "recuring": item.recuring ? item.recuring : null,
        "month_count": item.month_count ? item.month_count : null,
        "humanCase": item.humanCase ? item.humanCase : null,
        "humanYear": item.humanYear ? item.humanYear : null,
        "valid_flag": item.valid_flag ? item.valid_flag : null,
        "sponsor_id": item.sponsor_id ? item.sponsor_id : null,
        "cart_serial": item.cart_serial ? item.cart_serial : null,
        "orphan_id": item.orphan_id ? item.orphan_id : '0',
        "transaction_number": item.transaction_number ? item.transaction_number : null,
        "transaction_year": item.transaction_year ? item.transaction_year : null,
        "quantity": item.quantity? item.quantity: 1,
      }]
    }
  }
  try {
    const { data } = await bagAPIs.addToBag({ bag: cart.bag, language: myobject.language });
    dispatch({ type: ADD_ITEM_TO_BAG, payload: data });
    if (str==='justEdit') {
      toastifyMessage("Modified!", undefined, "success");
    } else {
      toastifyMessage("Added!", undefined, "success");
    }
    if (onHide) {
      onHide()
    }
    if (history) {
      history.goBack()
    }
  } catch (error) {
    toastifyMessage("Something went wrong...!", undefined, "error");
  }
};

export const getItemsFromBag = (userID, langNumber) => async (dispatch) => {
  dispatch({ type: LOAD_BAG });
  try {
    const { data } = await bagAPIs.getBagItems(userID, langNumber);
    dispatch({ type: FETCH_ALL_BAG_ITEMS, payload: data });
    dispatch({ type: BAG_LOADED });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({ type: BAG_LOADED });
  }
};
export const deleteFromBag = (userID, cartSerial, language, orphan_serial, onHide) => async (dispatch) => {
  try {
    const { data } = await bagAPIs.deleteFromBag(userID, cartSerial, language, orphan_serial? orphan_serial: orphan_serial? orphan_serial: "");
    dispatch({ type: DELETE_FROM_BAG, payload: data });
    toastifyMessage("Removed!", undefined, "success");
    if (onHide) {
      onHide()
    }
  } catch (error) {
    toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const addToFavorites = (lng, item, str) => async (dispatch) => {
  let favoriteItem = {};
  if (str && str==='array') {
    if (item.length === 0) {
      favoriteItem = {
        "language": lng,
        "favorites": [{
          "email": item.userEmail,
          "favoriteID": "",
          "dontypeID":  null,
          "projectID": null,
          "orptypeID": null,
          "countryID": null,
          "projectYear": null,
        }]
      }
    } else {
      favoriteItem = {
      "language": lng,
      "favorites": item
    }
    }
    
  } else {
    favoriteItem = {
      "language": lng,
      "favorites": [{
        "email": item.userEmail,
        "favoriteID": "",
        "dontypeID": item.dontypeID ? item.dontypeID : null,
        "projectID": item.projectID ? item.projectID : null,
        "orptypeID": item.orptypeID ? item.orptypeID : null,
        "countryID": item.countryID ? item.countryID : null,
        "projectYear": item.projectYear ? item.projectYear : null,
      }]
  }
  }
  try {
    const { data } = await bagAPIs.addToFavorites(favoriteItem);
    dispatch({ type: FETCH_ALL_FAVORITES, payload: data });
  } catch (error) {
    toastifyMessage("Something went wrong...!", undefined, "error");
  }
};

export const getItemsFromFavorites = (userID, langNumber) => async (dispatch) => {
  dispatch({ type: LOAD_FAVORITES });
  try {
    const { data } = await bagAPIs.getFavoritesItems(userID, langNumber);
    dispatch({ type: FETCH_ALL_FAVORITES, payload: data });
    dispatch({ type: LOADED_FAVORITES });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({ type: LOADED_FAVORITES });
  }
};

export const deleteFromFavorites = (lng, userID, favID) => async (dispatch) => {
  try {
    const { data } = await bagAPIs.deleteFromFavorites(lng, userID, favID);
    dispatch({ type: DELETE_FROM_FAVORITES, payload: data });
  } catch (error) {
    toastifyMessage("Something went wrong...!", undefined, "error");
  }
};